import styled from "styled-components";
import Body from './Comp/Body/Body'
import Banner from './Comp/Banner/Banner'

const HomeCSS = styled.div`
display:flex;
flex-direction: column;
`



function App() {
  return (
    <HomeCSS>
      <Banner/>
      <Body/>
    </HomeCSS>
  );
}

export default App;
