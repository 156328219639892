import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import styled from "styled-components";


const DefaultCSS = styled.div`
margin: 2%;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

box-sizing: border-box;

`


ReactDOM.render(
  <React.StrictMode>
    <DefaultCSS>
    <App />
    </DefaultCSS>
  </React.StrictMode>,
  document.getElementById('root')
);

