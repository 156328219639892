import styled from "styled-components";
import Chart from '../Chart/Chart'
import { useEffect, useRef } from "react";
const BodyCSS = styled.div`
display:flex;
border-style: solid;

`



function Body() {

  const svgRef = useRef(null);
  const margin = { top: 30, right: 30, bottom: 30, left: 60 }
  const width = 600;
  const height = 300;
  const svgWidth = width + margin.left + margin.right;
  const svgHeight = height + margin.top + margin.bottom;
  
  useEffect(() => {});
  return (
    <BodyCSS>
         <svg ref={svgRef} width={svgWidth} height={svgHeight} />
    </BodyCSS>
  );
}

export default Body;
