import styled from "styled-components";

const BannerCSS = styled.div`
display:flex;
flex-direction: column ;
border-style: solid;

`

const HelloCSS = styled.h1`
font-size: 400%;
margin: 0;
`

const SubHeaderCSS = styled.div`
font-size: 120%;
margin-top: 2%;

`

const SubSubHeaderCSS = styled.div`
font-size: 120%;
`

const NameCSS= styled.b`
font-size:120%;

&:hover {
    text-decoration: underline;

}
`


function Banner() {
  return (
    <BannerCSS>
        <HelloCSS>
        Hello. 👋
        </HelloCSS>
        <SubHeaderCSS>
      My Name is <NameCSS>Nawaf AlSane</NameCSS>. I'm a Data Scientists & Software Developer. 
      <SubSubHeaderCSS>
      This is my website to showcase of my projects
      </SubSubHeaderCSS>
      </SubHeaderCSS>
    </BannerCSS>
  );
}

export default Banner;
