import React from "react";
import * as d3 from "d3";
import styled from 'styled-components';


const ChartCSS = styled.div`
`
const Chart = () => {

    return (
        <ChartCSS>
            Hey
        </ChartCSS>
    )

}

export default Chart